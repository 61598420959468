/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */

*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}
.sw-pointer-events-none {
  pointer-events: none !important;
}
.sw-invisible {
  visibility: hidden !important;
}
.sw-absolute {
  position: absolute !important;
}
.sw-relative {
  position: relative !important;
}
.sw-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.sw-bottom-0 {
  bottom: 0 !important;
}
.sw-left-0 {
  left: 0 !important;
}
.sw-left-1\/2 {
  left: 50% !important;
}
.sw-left-12 {
  left: 3rem !important;
}
.sw-left-3 {
  left: 0.75rem !important;
}
.sw-right-0 {
  right: 0 !important;
}
.sw-right-10 {
  right: 2.5rem !important;
}
.sw-right-2 {
  right: 0.5rem !important;
}
.sw-top-0 {
  top: 0 !important;
}
.sw-top-1\/2 {
  top: 50% !important;
}
.sw-z-content-popup {
  z-index: 52 !important;
}
.sw-z-global-navbar {
  z-index: 421 !important;
}
.sw-z-global-popup {
  z-index: 5000 !important;
}
.sw-z-popup {
  z-index: 420 !important;
}
.sw-z-tooltip {
  z-index: 8000 !important;
}
.sw-z-top-navbar {
  z-index: 419 !important;
}
.sw--mx-2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}
.sw-mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.sw-mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}
.sw-mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.sw-mx-3 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}
.sw-mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.sw-my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.sw-my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.sw--ml-2 {
  margin-left: -0.5rem !important;
}
.sw--mt-2 {
  margin-top: -0.5rem !important;
}
.sw-ml-1 {
  margin-left: 0.25rem !important;
}
.sw-ml-2 {
  margin-left: 0.5rem !important;
}
.sw-ml-3 {
  margin-left: 0.75rem !important;
}
.sw-ml-4 {
  margin-left: 1rem !important;
}
.sw-mr-1 {
  margin-right: 0.25rem !important;
}
.sw-mr-2 {
  margin-right: 0.5rem !important;
}
.sw-mt-1 {
  margin-top: 0.25rem !important;
}
.sw-mt-2 {
  margin-top: 0.5rem !important;
}
.sw-box-border {
  box-sizing: border-box !important;
}
.sw-block {
  display: block !important;
}
.sw-inline-block {
  display: inline-block !important;
}
.sw-flex {
  display: flex !important;
}
.sw-inline-flex {
  display: inline-flex !important;
}
.sw-hidden {
  display: none !important;
}
.sw-h-0 {
  height: 0 !important;
}
.sw-h-4 {
  height: 1rem !important;
}
.sw-h-6 {
  height: 1.5rem !important;
}
.sw-h-auto {
  height: auto !important;
}
.sw-h-control {
  height: 2.25rem !important;
}
.sw-min-h-10 {
  min-height: 2.5rem !important;
}
.sw-min-h-4 {
  min-height: 1rem !important;
}
.sw-w-0 {
  width: 0 !important;
}
.sw-w-24 {
  width: 6rem !important;
}
.sw-w-4 {
  width: 1rem !important;
}
.sw-w-abs-150 {
  width: 150px !important;
}
.sw-w-full {
  width: 100% !important;
}
.sw-min-w-0 {
  min-width: 0 !important;
}
.sw-min-w-4 {
  min-width: 1rem !important;
}
.sw-min-w-abs-200 {
  min-width: 200px !important;
}
.sw-min-w-abs-350 {
  min-width: 350px !important;
}
.sw-min-w-input-small {
  min-width: 150px !important;
}
.sw-max-w-\[22rem\] {
  max-width: 22rem !important;
}
.sw-max-w-abs-350 {
  max-width: 350px !important;
}
.sw-max-w-abs-600 {
  max-width: 600px !important;
}
.sw-flex-1 {
  flex: 1 1 0% !important;
}
.sw-flex-auto {
  flex: 1 1 auto !important;
}
.sw-flex-shrink {
  flex-shrink: 1 !important;
}
.sw-cursor-default {
  cursor: default !important;
}
.sw-cursor-not-allowed {
  cursor: not-allowed !important;
}
.sw-cursor-pointer {
  cursor: pointer !important;
}
.sw-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}
.sw-appearance-none {
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
}
.sw-flex-col {
  flex-direction: column !important;
}
.sw-items-start {
  align-items: flex-start !important;
}
.sw-items-end {
  align-items: flex-end !important;
}
.sw-items-center {
  align-items: center !important;
}
.sw-items-stretch {
  align-items: stretch !important;
}
.sw-justify-start {
  justify-content: flex-start !important;
}
.sw-justify-center {
  justify-content: center !important;
}
.sw-justify-between {
  justify-content: space-between !important;
}
.sw-gap-8 {
  gap: 2rem !important;
}
.sw-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.25rem*(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.25rem*var(--tw-space-y-reverse)) !important;
}
.sw-overflow-auto {
  overflow: auto !important;
}
.sw-overflow-hidden {
  overflow: hidden !important;
}
.sw-overflow-y-auto {
  overflow-y: auto !important;
}
.sw-overflow-x-hidden {
  overflow-x: hidden !important;
}
.sw-truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.sw-overflow-ellipsis {
  text-overflow: ellipsis !important;
}
.sw-whitespace-nowrap {
  white-space: nowrap !important;
}
.sw-break-words {
  overflow-wrap: break-word !important;
}
.sw-rounded-1 {
  border-radius: 0.25rem !important;
}
.sw-rounded-1\/2 {
  border-radius: 0.125rem !important;
}
.sw-rounded-2 {
  border-radius: 0.5rem !important;
}
.sw-rounded-pill {
  border-radius: 625rem !important;
}
.sw-rounded-l-1 {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.sw-border-solid {
  border-style: solid !important;
}
.sw-border-transparent {
  border-color: transparent !important;
}
.sw-object-contain {
  object-fit: contain !important;
}
.sw-p-1 {
  padding: 0.25rem !important;
}
.sw-px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.sw-px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.sw-px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.sw-px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.sw-px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.sw-px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.sw-px-\[0\.125rem\] {
  padding-left: 0.125rem !important;
  padding-right: 0.125rem !important;
}
.sw-px-\[0\.625rem\] {
  padding-left: 0.625rem !important;
  padding-right: 0.625rem !important;
}
.sw-py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.sw-py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.sw-py-\[0\.03125rem\] {
  padding-top: 0.03125rem !important;
  padding-bottom: 0.03125rem !important;
}
.sw-pb-3 {
  padding-bottom: 0.75rem !important;
}
.sw-pb-4 {
  padding-bottom: 1rem !important;
}
.sw-pl-10 {
  padding-left: 2.5rem !important;
}
.sw-pr-10 {
  padding-right: 2.5rem !important;
}
.sw-pt-1 {
  padding-top: 0.25rem !important;
}
.sw-pt-4 {
  padding-top: 1rem !important;
}
.sw-text-left {
  text-align: left !important;
}
.sw-text-center {
  text-align: center !important;
}
.sw-text-right {
  text-align: right !important;
}
.sw-align-top {
  vertical-align: top !important;
}
.sw-align-middle {
  vertical-align: middle !important;
}
.sw-font-sans {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}
.sw-text-\[0\.75rem\] {
  font-size: 0.75rem !important;
}
.sw-text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}
.sw-font-regular {
  font-weight: 400 !important;
}
.sw-font-semibold {
  font-weight: 600 !important;
}
.sw-leading-\[0\.938rem\] {
  line-height: 0.938rem !important;
}
.sw-leading-\[1\.125rem\] {
  line-height: 1.125rem !important;
}
.sw-no-underline {
  -webkit-text-decoration-line: none !important;
          text-decoration-line: none !important;
}
.sw-transition-none {
  transition-property: none !important;
}
.sw-body-md {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  font-weight: 400 !important;
}
.sw-body-md-highlight {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  font-weight: 600 !important;
}
.sw-body-sm {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  font-weight: 400 !important;
}
.sw-body-sm-highlight {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  font-weight: 600 !important;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
}

*:focus-visible {
  outline: 2px dotted #297BAE;
}

html,
body {
  background-color: #f3f3f3;
}

body {
  overflow-y: scroll;
}

fieldset,
img {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
th,
var {
  font-style: normal;
  font-weight: normal;
}

caption,
th {
  text-align: left;
}

q:before,
q:after {
  content: '';
}

abbr,
acronym {
  border: 0;
  font-variant: normal;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

select,
input,
button,
textarea {
  font-size: inherit;
  font-family: inherit;
}

textarea {
  min-height: 40px;
}

/*Remove button padding in FF*/
select::-moz-focus-inner,
input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

pre,
code,
kbd,
samp,
tt {
  font-family: Consolas, 'Ubuntu Mono', 'Liberation Mono', Menlo, Courier, monospace;
  font-size: 12px;
  line-height: 18px;
}

svg,
img {
  vertical-align: top;
  overflow: visible;
}

hr {
  margin: 4px 0;
  padding: 0;
  height: 1px;
  border: none;
  background-color: #e6e6e6;
}
