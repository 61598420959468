/*
 * SonarQube
 * Copyright (C) 2009-2023 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
#settings-page .layout-page-side,
#settings-page .layout-page-side-outer {
  width: calc(50vw - 480px);
  border-right: none;
}

#settings-page .layout-page-side-inner {
  width: 160px;
  margin-left: calc(50vw - 639px); /* 640px -1px for overlapping the border */
}

#settings-page .layout-page-main {
  padding: 0;
}

#settings-page .layout-page-main-inner {
  max-width: 1110px;
}

#settings-page .top-bar-outer {
  height: 120px;
}

#settings-page .top-bar {
  background-color: #f3f3f3;
  position: fixed;
  z-index: 55; /* todo */
  left: 0;
  right: 0;
}

#settings-page .top-bar-inner {
  max-width: 1280px;
  margin: 0 auto;
  height: 120px;
  box-sizing: border-box;
}

#settings-page .page-title,
#settings-page .page-description {
  float: none;
}

.settings-definitions-list > li + li {
  margin-top: 30px;
}

.settings-definition {
  margin: 0 -20px;
  padding: 10px 20px;
  border-top: 1px solid rgba(0, 0, 0, 0);
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  display: flex;
  align-items: stretch;
}

.tabbed-definitions .settings-definition {
  margin: 0 -16px;
  padding: 10px 16px;
}

.settings-definition-changed {
  border-top: 1px solid #faebcc;
  border-bottom: 1px solid #faebcc;
  background-color: #fcf8e3;
}

.settings-definition-left {
  width: 330px;
  padding-right: 30px;
  box-sizing: border-box;
}

.settings-definition-right {
  position: relative;
  width: calc(100% - 330px);
  box-sizing: border-box;
}

.settings-definition-name {
  text-overflow: ellipsis;
}

.settings-definition-key {
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.settings-definition-state {
  min-height: 32px;
  padding-bottom: 4px;
}

.settings-definition-state > span {
  display: flex;
}

.settings-definition-changes {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px dotted #e6e6e6;
}

.settings-sub-categories-list > li + li,
.settings-sub-category {
  margin: 30px -20px 0;
  padding: 30px 20px;
  border-top: 1px solid #e6e6e6;
}

.settings-sub-category-name {
  margin-bottom: 20px;
  font-size: 16px;
}

.settings-sub-category-description {
  margin-top: -15px;
  margin-bottom: 20px;
  color: #656565;
}

.settings-large-input {
  width: 100% !important;
  max-width: 400px;
  min-width: 200px;
}

.side-tabs-menu {
  margin-top: 16px;
}

.side-tabs-menu > li {
  margin-bottom: 4px;
}

.side-tabs-menu > li > a {
  display: block;
  padding: 10px 10px;
  line-height: 1.5;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border: 1px solid #e6e6e6;
  border-right: none;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.side-tabs-menu > li > a:hover,
.side-tabs-menu > li > a:focus,
.side-tabs-menu > li > a.active {
  background-color: #fff;
}

.side-tabs-menu > li > a.active {
  color: #333;
  cursor: default;
}

@media (max-width: 1320px) {
  #settings-page .layout-page-side-outer,
  #settings-page .layout-page-side {
    width: 180px;
  }

  #settings-page .layout-page-side-inner {
    margin-left: 20px;
  }

  #settings-page .top-bar-inner {
    margin: 0 20px;
  }
}

.settings-search-results {
  max-height: 50vh;
  width: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.settings-search-results > li > a:hover {
  background-color: unset;
  border-left-color: unset;
}

.settings-search-results > li.active > a {
  background-color: #F3F3F3;
  border-left-color: rgba(0, 0, 0, 0.60);
}

.fixed-footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
  align-items: center;
  display: flex;
  border: 1px solid #cdcdcd;
  background-color: white;
  justify-content: space-between;
  margin: 0px -16px;
}

.markdown-preview {
  width: 450px;
  background-color: #ECF6FE;
  border: 1px solid #B1DFF3;
  border-radius: 2px;
  padding: 16px;
  overflow-wrap: break-word;
}

.saml-enabled {
  color: #008223;
}

.saml-no-config {
  background-color: #F3F3F3;
  color: rgba(0, 0, 0, 0.60);
}

.saml-configuration .radio-card {
  width: 50%;
  background-color: #F3F3F3;
  border: 1px solid #CCCCCC;
}

.saml-configuration .radio-card.selected {
  background-color: #ECF6FE;
  border: 1px solid #0271B9;
}

.saml-configuration .radio-card:hover:not(.selected) {
  border: 1px solid #0271B9;
}

.saml-configuration fieldset > div {
  justify-content: space-between;
}

.saml-configuration .radio-card-header {
  justify-content: space-around;
}

.saml-configuration .radio-card-body {
  justify-content: flex-start;
}

.saml-configuration .settings-definition-left {
  width: 50%;
}

.saml-configuration .settings-definition-right {
  display: flex;
  align-items: center;
  width: 50%;
}
